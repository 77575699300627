import React from 'react';
import PropTypes from 'prop-types';
import { Box, Type, Grid } from 'sassafras';

const Faq = ({
    brandName,
}) => (
    <Box align="center" padding="xl">
        <Box width="1080px" margin="auto">
            <Grid columns="1fr" rowGap="md">
                <Type tag="h1" font="h1">
                    Frequently Asked Questions (FAQ)
                </Type>
                <Type tag="h3" font="h3">
                    What is
                    {' '}
                    {brandName}
                    ?
                </Type>
                <Type>
                    {brandName}
                    {' '}
                    offers a free infomediary service that will attempt to connect you with independent,
                    third party lenders and financial service providers who may secure a personal loan for you. The
                    operator of this website is not a lender, nor a broker, agent or representative to any lender and
                    does not make personal loans or credit decisions.
                </Type>

                <Type tag="h3" font="h3">
                    How much can I borrow from a Lender I get connected with?
                </Type>
                <Type tag="p">
                    A personal loan can generally range from $500 to $35,000, depending on the consumer. The loan amount
                    you may be approved for depends upon your individual credit, information provided during the
                    application process, and certain lender specific criteria. Please contact your loan provider
                    directly with any questions you may have regarding your personal loan.
                </Type>

                <Type tag="h3" font="h3">
                    What will my loan terms be?
                </Type>
                <Type tag="p">
                    Only a lender can provide accurate information on your specific loan terms. This is provided to you
                    through documents that contain all rate and fee information regarding the loan being offered to you.
                    It is important that you thoroughly review the rate and fee information provided to you by your
                    lender prior to agreeing to the terms of any loan. If you wish to proceed with the loan process
                    after deciding that the loan terms provided by the lender in the loan contract are acceptable, then
                    you should follow the lenders instruction. Any time before you sign the loan documents, you may
                    withdraw from process.
                </Type>

                <Type tag="h3" font="h3">
                    How long does it take to receive a personal loan offer?
                </Type>
                <Type tag="p">
                    We send out your registration form in real time and depending upon your personal information you may
                    be contacted within minutes by a lender. Once connected with a Lender, they will provide you with
                    your loan offer and loan terms, including time periods. We have no control over the loan terms or
                    timing of any loan products offered by lenders who contact you.
                </Type>

                <Type tag="h3" font="h3">
                    What if I have issues paying my Lender back?
                </Type>
                <Type tag="p">
                    Lenders may have different policies regarding late payments. Please ensure that you thoroughly
                    review any late payment disclosures or information detailed in any of the loan documents and loan
                    contract your lender provides before electronically signing. It is important to note that all
                    lenders must abide by applicable federal and state laws and regulations regarding late payment
                    fees. You are encouraged to research and review the applicable laws regarding personal loans and
                    late payments in the state which you reside.
                </Type>

                <Type tag="h3" font="h3">
                    What are my options if I don&lsquo;t qualify for a Personal Loan?
                </Type>
                <Type tag="p">
                    {brandName}
                    {' '}
                    makes every effort to get your registration to a group of entities providing personal
                    loan products. But we won’t leave you if your registration is not picked up or you do not qualify.
                    We have worked hard to find other third parties who offer other short-term loans to assist
                    consumers with varying types of creditworthiness. So, if your registration is declined or does not
                    get picked up right away, we will let you know and provide you the option to have your registration
                    form sent or directly connect you to a lender or financial service provider that offers other
                    short-term loans. Typically, the majority of consumers connected with lenders who offer loans are
                    only presented with a short-term lending options between $100 - $1,000. These loans may have less
                    favorable rates and shorter terms than personal loans. We encourage you to research the best loan
                    option for your situation.
                </Type>
            </Grid>
        </Box>
    </Box>
);

Faq.propTypes = {
    brandName: PropTypes.string,
};

Faq.defaultProps = {};

export default Faq;
