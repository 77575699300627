import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Type, ThemeContext } from 'sassafras';

const styles = createUseStyles({
    Nav: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        '@media screen and (max-width: 768px)': {
            display: 'block',
        },
    },
    Item: {
        padding: ({ theme }) => [[theme.spacing.xs, theme.spacing.md]],
    },
});

const Nav = ({
    nav, font, color, weight,
}) => {
    const theme = useContext(ThemeContext);

    const classes = styles({ theme });

    const mapNav = () => nav.map((item) => (
        <div className={classes.Item} key={item.key}>
            <Type
                tag="a"
                href={item.href}
                color={color}
                font={font}
                target="_blank"
                weight={weight}
            >
                {item.label}
            </Type>
        </div>
    ));

    return (
        <div className={classes.Nav}>
            {mapNav()}
        </div>
    );
};

Nav.propTypes = {
    nav: PropTypes.arrayOf(PropTypes.object),
    font: PropTypes.string,
    color: PropTypes.string,
    weight: PropTypes.string,
};

Nav.defaultProps = {};

export default Nav;
