import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Menu } from 'react-feather';
import { useMediaQuery } from 'react-responsive';
import { ThemeContext, ToolTip, Btn } from 'sassafras';

const styles = createUseStyles({
    Header: {
        display: 'flex',
        position: 'relative',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'center',
        background: ({ bgColor, theme }) => theme.colors[bgColor] || bgColor,
        zIndex: 90000,
    },
    Body: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        maxWidth: 1200,
        padding: ({ theme }) => theme.spacing.md,
    },
    Footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: ({ theme }) => [[theme.spacing.sm, theme.spacing.md]],
        background: ({ navBgColor, theme }) => theme.colors[navBgColor] || navBgColor,
    },
    MobileMenu: {
        width: '100%',
        position: 'absolute',
        left: 0,
        top: '100%',
    },
    Logo: {
        width: '100%',
        maxWidth: '240px',
        '& img': {
            display: 'block',
            width: '100%',
        },
    },
    '@media (max-width: 869px)': {
        Body: {
            flexWrap: 'wrap',
        },
    },
});

const Header = ({
    logo, search, jobOptions, nav, ...props
}) => {
    const [showMenu, setShowMenu] = useState(false);

    const theme = useContext(ThemeContext);

    const classes = styles({ theme, ...props });

    const mobile = useMediaQuery({ maxWidth: 768 });

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <div className={classes.Header}>
            <div className={classes.Body}>
                <div className={classes.Logo}>
                    <img src={logo} alt="logo" />
                </div>
                {mobile && nav && (
                    <Btn rounded={false} size="sm" onClick={toggleMenu} margin={[0, 0, 0, 'md']}>
                        <Menu />
                    </Btn>
                )}
            </div>
            {!mobile && nav && (
                <div className={classes.Footer}>
                    {nav}
                </div>
            )}
            {mobile && nav && (
                <div className={classes.MobileMenu}>
                    <ToolTip in={showMenu} tip={false} rounded={false} color="primary800">
                        {nav}
                    </ToolTip>
                </div>
            )}
        </div>
    );
};

Header.propTypes = {
    logo: PropTypes.string,
    bgColor: PropTypes.string,
    navBgColor: PropTypes.string,
    jobOptions: PropTypes.object,
    search: PropTypes.bool,
    nav: PropTypes.node,
};

Header.defaultProps = {};

export default Header;
