import React from 'react';
import PropTypes from 'prop-types';
import { Box, H1 } from 'sassafras';
import RawHTML from './RawHTML';

const BlogPost = ({ title, content }) => (
    <Box align="center" padding="xl">
        <Box width="1080px" margin="auto">
            <H1 mb="lg">{title}</H1>
            <RawHTML html={content} />
        </Box>
    </Box>
);

BlogPost.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
};

export default BlogPost;
