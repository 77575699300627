import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import {
    ThemeContext, Box, Type,
} from 'sassafras';

const styles = createUseStyles({
    Footer: {
        width: '100%',
        '& a': {
            color: 'inherit',
            textDecoration: 'none',
        },
    },
});

const Footer = ({
    brandName, nav, bgColor, ...props
}) => {
    const theme = useContext(ThemeContext);

    const classes = styles({ theme, ...props });

    return (
        <div className={classes.Footer}>
            <Box bgColor={bgColor} padding="lg" align="center">
                <Box width="1080px" txtColor="white" margin="auto">
                    <Type tag="p" font="control" spacingB="md">
                        Disclaimer: This website is a blog and for informational purposes only. The website and the
                        owner of this website is not a lender or broker, not a creditor or financial service provider,
                        and its only financial connection to the providers of the goods and services referred to on
                        the website or appearing on this website is a marketing vendor. As such, if applicable, we
                        may receive compensation, in the form of a payment for each qualified sale or for each customer
                        registration form or submission of information we submit. The owner may also be advertising
                        on behalf of a third-party advertiser with whom the provider of the products or services also
                        has a financial connection with, in that both advertisers may receive compensation for each
                        qualified sale or payment for each customer referral. Therefore, the amount of compensation
                        provided, along with other factors, may impact the entity to whom we provide your registration
                        form or submission of information. Upon submitting a registration form or your information,
                        you may be redirected to the third-party website or contacted by the third-party for the
                        product you have requested. We encourage you to review the third-party privacy practices
                        and terms and conditions, which may and will differ substantially from this website. This
                        website and its owners are not affiliated with these third-party other than what has been
                        described above. The owner of this website does not recommend or endorse any specific product,
                        lender, or financial service on this website and this website does not include all companies
                        or all available offers. You are encouraged to research all available loan and financial
                        products and services offers for your situation. We make no warranties, guarantees, or
                        representations that you will be able to qualify for any of the products and/or services
                        presented on this website by third-parties. Nothing on this website is intended to be
                        construed as financial or legal advice and we encourage you to consult with a financial
                        advisor prior to entering into any transaction. We do not endorse or recommend any services
                        and/or products advertised on or through this website. Residents of some states may not
                        qualify for the third-party products and services appearing or presented on this website.
                        The services provided on this website are void where prohibited.
                    </Type>
                </Box>
            </Box>
            <Box bgColor={bgColor} align="center">
                <Box txtColor="white" width="1200px" padding="md" margin="auto">
                    {nav}
                    <Type tag="p" font="control" align="center">
                        &copy; 2020
                        {' '}
                        {brandName}
                        , All Rights Reserved.
                    </Type>
                </Box>
            </Box>
        </div>
    );
};

Footer.propTypes = {
    brandName: PropTypes.string,
    nav: PropTypes.node,
    bgColor: PropTypes.string,
};

Footer.defaultProps = {};

export default Footer;
