/* eslint-disable react/no-danger */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import * as DOMPurify from 'dompurify';
import { createUseStyles } from 'react-jss';
import { ThemeContext } from 'sassafras';

const styles = createUseStyles({
    HtmlContent: {
        '& p': {
            marginBottom: '1rem',
            fontFamily: ({ theme }) => theme.typography.p.fontFamily,
            fontSize: ({ theme }) => theme.typography.p.fontSize,
        },
        '& h1': {
            marginBottom: '1rem',
            fontFamily: ({ theme }) => theme.typography.h1.fontFamily,
            fontSize: ({ theme }) => theme.typography.h1.fontSize,
        },
        '& h2': {
            marginBottom: '1rem',
            fontFamily: ({ theme }) => theme.typography.h2.fontFamily,
            fontSize: ({ theme }) => theme.typography.h2.fontSize,
        },
        '& h3': {
            marginBottom: '1rem',
            fontFamily: ({ theme }) => theme.typography.h3.fontFamily,
            fontSize: ({ theme }) => theme.typography.h3.fontSize,
        },
        '& h4': {
            marginBottom: '1rem',
            fontFamily: ({ theme }) => theme.typography.h4.fontFamily,
            fontSize: ({ theme }) => theme.typography.h4.fontSize,
        },
        '& h5': {
            marginBottom: '1rem',
            fontFamily: ({ theme }) => theme.typography.h5.fontFamily,
            fontSize: ({ theme }) => theme.typography.h5.fontSize,
        },
        '& h6': {
            marginBottom: '1rem',
            fontFamily: ({ theme }) => theme.typography.h6.fontFamily,
            fontSize: ({ theme }) => theme.typography.h6.fontSize,
        },
        '& ul': {
            listStyleType: 'disc',
            listStylePosition: 'outside',
            marginBottom: '1rem',
        },
        '& ol': {
            listStyleType: 'decimal',
            listStylePosition: 'outside',
            marginBottom: '1rem',
        },
        '& li': {
            fontFamily: ({ theme }) => theme.typography.p.fontFamily,
            fontSize: ({ theme }) => theme.typography.p.fontSize,
        },
        '& blockquote': {
            borderLeft: ({ theme }) => `1px solid ${theme.colors.neutral}`,
            paddingLeft: '1rem',
            marginBottom: '1rem',
            fontFamily: ({ theme }) => theme.typography.p.fontFamily,
            fontSize: ({ theme }) => theme.typography.p.fontSize,
        },
    },
});

const RawHTML = ({
    html,
}) => {
    const clean = DOMPurify.sanitize(html);
    const theme = useContext(ThemeContext);
    const classes = styles({ theme });

    return (
        <div className={classes.HtmlContent} dangerouslySetInnerHTML={{ __html: clean }} />
    );
};

RawHTML.propTypes = {
    html: PropTypes.string,
};

export default RawHTML;
